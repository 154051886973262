import { Workspace } from "~/scalis-components/core/navbar/navbar-context/navbar-context.types";

export const MOCK_WORKSPACE = "microsoft";
export const WORKSPACE_GOOGLE = "google";
export const WORKSPACE_MICROSOFT = "microsoft";
export const WORKSPACE_SKYPE = "skype";
export const WORKSPACE_GITHUB = "github";
export const WORKSPACE_BLIZZARD = "blizzard";

export const MOCK_WORKSPACES: Workspace[] = [
  {
    subdomain: "Google",
    name: WORKSPACE_GOOGLE,
    imageSrc: "/images/google-logo.svg",
    id: 498598,
  },
  {
    subdomain: "Microsoft",
    name: WORKSPACE_MICROSOFT,
    imageSrc: "/images/microsoft-logo.svg",
    id: 604893,
  },
  {
    subdomain: "Skype",
    name: WORKSPACE_SKYPE,
    imageSrc: "/images/skype-logo.svg",
    id: 12345,
  },
  {
    subdomain: "GitHub",
    name: WORKSPACE_GITHUB,
    imageSrc: "/images/github-logo.svg",
    id: 419305,
  },
  {
    subdomain: "Blizzard",
    name: WORKSPACE_BLIZZARD,
    imageSrc: "/images/blizzard-logo.svg",
    id: 305869,
  },
];
