import { Workspace } from "../../../navbar-context/navbar-context.types";

export const getWorkspace = (id: number | null, workspaces: Workspace[]) => {
  if (!workspaces?.length || !id) {
    return {} as Workspace;
  }

  const defaultWorkspace = workspaces[0];
  const workspaceInfo =
    workspaces.find(workspace => workspace.id === id) ?? defaultWorkspace;

  return workspaceInfo;
};
