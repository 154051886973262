import { useRouter, usePathname } from "next/navigation";
import { LoginMenuButton } from "../../../login-menu-button";
import { SignupMenuButton } from "../../../signup-menu-button";
import { useSession } from "next-auth/react";
import { AccountDropdownMenu } from "../../../account-dropdown-menu";
import { WorkspacesDropdownMenu } from "../../../workspaces-dropdown-menu";

export const NavbarButtons = () => {
  const router = useRouter();
  const pathname = usePathname();

  const { status, data: session } = useSession();
  const isSignedIn = status === "authenticated" && session.user.emailVerified;

  const getCallbackUrl = () => {
    if (!pathname) {
      return "";
    }

    return encodeURIComponent(pathname);
  };

  if (!isSignedIn) {
    return (
      <div className="flex gap-2">
        <LoginMenuButton
          onClick={() => router.push(`/login?callbackUrl=${getCallbackUrl()}`)}
        />
        <SignupMenuButton
          onClick={() => router.push(`/signup?callbackUrl=${getCallbackUrl()}`)}
        />
      </div>
    );
  }

  return (
    <>
      <div className="hidden h-6 border-r border-neutral-00 lg:block" />
      {isSignedIn && (
        <AccountDropdownMenu
          email={session?.user?.email}
          imageSrc={session?.user?.image ?? ""}
          name={session?.user?.name!}
        />
      )}

      <div className="hidden h-6 border-r border-neutral-00 sm:block" />
      <WorkspacesDropdownMenu />
    </>
  );
};
