"use client";

import React from "react";

import Image from "next/image";
import {
  EMPLOYEE_WORKSPACE_BUTTON_ALT,
  EMPLOYEE_WORKSPACE_BUTTON_ICON,
  EMPLOYEE_WORKSPACE_BUTTON_WIDTH,
  EMPLOYEE_WORKSPACE_BUTTON_HEIGHT,
} from ".";

export const EmployeeWorkspaceButton = () => {
  return (
    <div className="rounded-lg transition-colors duration-150 hover:bg-button-brand-primary-light-hover active:bg-button-brand-primary-light-hover">
        <Image
          src={EMPLOYEE_WORKSPACE_BUTTON_ICON}
          alt={EMPLOYEE_WORKSPACE_BUTTON_ALT}
          width={EMPLOYEE_WORKSPACE_BUTTON_WIDTH}
          height={EMPLOYEE_WORKSPACE_BUTTON_HEIGHT}
          priority
        />
    </div>
  );
};
