import { z } from "zod";

export const schema = z.object({
  search: z.string().optional(),
  jobTitle: z.string().optional(),
  location: z.string().optional(),
  employeeType: z.string().optional(),
});

export type FormValues = z.infer<typeof schema>;

export interface SearchMenuProps {
  onSubmit?: (data: FormValues) => void;
}
