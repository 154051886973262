import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import useBreakpoint from "~/hooks/useBreakpoint";
import { cn } from "~/utils/cn";
import logger from "~/utils/logger";
import { Button, ButtonKind, ButtonSize, ButtonWidth } from "../../button";
import { InputSearch } from "../../input";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover";
import { Select } from "../../select";
import { Body, BodySize, TextAlign } from "../../typography";
import { FormValues, SearchMenuProps, schema } from "./search-menu.types";

export const SearchMenu: React.FC<SearchMenuProps> = ({ onSubmit }) => {
  const { lg } = useBreakpoint();
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleSubmit, setValue, watch, getValues } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  return (
    <Popover
      open={isOpen}
      onOpenChange={() => {
        if (!lg) {
          setIsOpen(!isOpen);
        }
      }}
    >
      <PopoverTrigger asChild>
        <div className={cn("h-9 w-full", { "h-10": !lg })}>
          {!isOpen && (
            <InputSearch
              name="search"
              placeholder="Search"
              search={watch("search") ?? ""}
              medium={lg}
              onChangeSearch={value => {
                setValue("search", value);
              }}
              onPressEnter={() => {
                const data = getValues();
                onSubmit?.(data);
              }}
            />
          )}
        </div>
      </PopoverTrigger>
      {!lg && (
        <PopoverContent
          alignOffset={10}
          className="flex w-screen rounded-none bg-white px-4 py-3"
        >
          <form
            onSubmit={handleSubmit(
              data => {
                onSubmit?.(data);
              },
              error => {
                logger.info("🚀 ~ error:", error);
              },
            )}
            className="flex w-full flex-col gap-2"
          >
            <InputSearch
              name="jobTitle"
              placeholder="Job Title / Keyword"
              search={watch("jobTitle") ?? ""}
              onChangeSearch={value => {
                setValue("jobTitle", value);
              }}
            />
            <InputSearch
              name="location"
              placeholder="Location"
              search={watch("location") ?? ""}
              icon="fa-regular fa-location-dot"
              onChangeSearch={value => {
                setValue("location", value);
              }}
            />
            <Select
              name="employeeType"
              onChange={value => setValue("employeeType", value)}
              placeholder="Employee Type"
              icon="fa-regular fa-briefcase"
              emptyTerm={"Employee Type"}
              options={[]}
              searchPlaceholder="Employee Type"
              startAdornment={<i className="fa-regular fa-briefcase"></i>}
              setValue={value => {
                setValue("employeeType", value);
              }}
              value={""}
              emptyIcon={
                <i className="fa-regular fa-briefcase text-icon-neutral-20" />
              }
            />
            <div className="flex w-full gap-2">
              <Button
                kind={ButtonKind.secondary}
                width={ButtonWidth.half}
                size={ButtonSize.medium}
                aria-label="Cancel Search Button"
                onClick={() => setIsOpen(false)}
              >
                <Body size={BodySize.small} textAlign={TextAlign.center}>
                  Cancel
                </Body>
              </Button>
              <Button
                kind={ButtonKind.primary}
                width={ButtonWidth.half}
                size={ButtonSize.medium}
                aria-label="Submit Search Button"
              >
                <Body size={BodySize.small} textAlign={TextAlign.center}>
                  Search
                </Body>
              </Button>
            </div>
          </form>
        </PopoverContent>
      )}
    </Popover>
  );
};
