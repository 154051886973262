import DatadogWinston from "datadog-winston";
import { datadogLogs } from '@datadog/browser-logs';

const isClient = typeof window !== "undefined";

type Logger = {
  error: (...args: any[]) => void,
  info: (...args: any[]) => void,
  warn: (...args: any[]) => void,
};

let logger: Logger;
if (isClient) {
  logger = {
    error: (...args) => {
      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true") {
        const errorMessage = args.map(arg => (arg instanceof Error ? arg.message : String(arg))).join(" ");
        const errorStack = args.find(arg => arg instanceof Error)?.stack;

        datadogLogs.logger.error(errorMessage, {
          errorStack,
          additionalInfo: args,
        });
      }
      console.error("Client-side error:", ...args);
    },
    info: (...args) => {
      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true") {
        datadogLogs.logger.info("info", { message: args.join(" ") });
      } else {
        console.info("Client-side info:", ...args);
      }
    },
    warn: (...args) => {
      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true") {
        datadogLogs.logger.warn("warn", { message: args.join(" ") });
      }
      console.warn("Client-side warn:", ...args);
    },
  };
} else {
  const winston = require("winston");
  const { format } = winston;

  global.setImmediate =
    global.setImmediate ||
    ((fn: (...args: unknown[]) => void, ...args: unknown[]) =>
      global.setTimeout(fn, 0, ...args));

  // @ts-ignore
  const errorStackFormat = format(info => {
    if (info instanceof Error) {
      return Object.assign({}, info, {
        message: `${info.message} ${info.stack}`.replace(/\n/g, " "),
        stack: info.stack,
      });
    }
    if (info.error && Object.keys(info.error).length > 0) {
      return Object.assign({}, info, {
        message: `${info.error.message} ${info.error.stack}`.replace(/\n/g, " "),
        stack: info.error.stack,
      });
    }
    return info;
  });

  const transporters: any = [
    new winston.transports.Console({
      format: format.combine(
        format.timestamp(),
        // @ts-ignore
        format.printf(({ timestamp, level, message, ...rest }) => {
          return `${timestamp} ${level}: ${message} ${JSON.stringify(rest)}`;
        }),
      ),
    }),
  ];

  if (process.env.DATADOG_API_KEY) {
    const datadogTransport = new DatadogWinston({
      apiKey: process.env.DATADOG_API_KEY,
      hostname: process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL,
      service: `scalis-${process.env.DATADOG_ENV}`,
      ddsource: "scalis-io-nextjs",
      ddtags: `service:scalis-${process.env.DATADOG_ENV}, env:${process.env.DATADOG_ENV}, `,
    });
    transporters.push(datadogTransport);
  }

  logger = winston.createLogger({
    format: format.combine(
      format.timestamp(),
      format.json({ space: 0 }),
      errorStackFormat(),
    ),
    defaultMeta: { service: "scalis" },
    transports: transporters,
  });
}

export default logger;
