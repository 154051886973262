"use client";

import { CompanyAvatar } from "~/scalis-components/core/company-avatar";

import React from "react";
import { cn } from "~/utils/cn";
import { WorkspaceButtonProps } from "./workspace-button.types";
import { getWorkspace } from "./workspace-button.utils";
import { useNavbarContext } from "../../../navbar-context";
import { useSignedAWSUrl } from "~/hooks/file-upload/use-signed-aws-url";

export const WorkspaceButton = ({ isOpen }: WorkspaceButtonProps) => {
  const { workspaces, currentWorkspace } = useNavbarContext();
  const workspaceInfo =
    currentWorkspace && workspaces
      ? getWorkspace(currentWorkspace.id, workspaces)
      : undefined;
  const logoUrl = useSignedAWSUrl(workspaceInfo?.imageSrc);

  if (!currentWorkspace || !workspaces) {
    return null;
  }

  return (
    <div className="group flex h-10 items-center gap-2 rounded-xl border border-neutral-10 p-1.5 text-sm font-medium text-neutral-primary transition-colors duration-150 hover:bg-button-brand-primary-light-hover active:bg-button-brand-primary-light-hover">
      <CompanyAvatar
        imageSrc={logoUrl}
        name={workspaceInfo?.subdomain ?? ""}
        size="xs"
      />
      <span className="hidden max-w-[105px] overflow-hidden text-ellipsis text-nowrap md:block">
        {workspaceInfo?.name || workspaceInfo?.subdomain}
      </span>
      <i
        aria-label="icon workspace menu"
        className={cn("fa-solid fa-caret-down", { "fa-rotate-180": isOpen })}
      />
    </div>
  );
};
