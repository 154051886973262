import { useSession } from "next-auth/react";
import React, { PropsWithChildren } from "react";
import { Divider } from "~/scalis-components/core/divider";
import { useNavbarContext } from "../../../navbar-context/navbar-context";
import { getWorkspace } from "../workspace-button";
import { useSubdomainRouter } from "~/hooks/use-subdomain-router";
import { Routes } from "~/src/app/_constants/routes";
import { WorkspacesDropdownSubmenuItem } from "./workspaces-dropdown-submenu-item";

export const WorkspacesDropdownSubmenuContent: React.FC<
  PropsWithChildren
> = () => {
  const { workspaces, currentWorkspace, setCurrentWorkspace } =
    useNavbarContext();

  const { update } = useSession();

  const { push } = useSubdomainRouter();

  if (!workspaces || !currentWorkspace) return null;

  return (
    <>
      <div className="flex flex-col gap-2 p-2">
        {workspaces.map(item => {
          const isCurrentWorkspace = item.id === currentWorkspace.id;
          const { imageSrc, name, subdomain } =
            getWorkspace(item.id, workspaces) ?? workspaces[0];

          return (
            <WorkspacesDropdownSubmenuItem
              key={item.id}
              id={item.id}
              name={name}
              subdomain={subdomain}
              imageSrc={imageSrc}
              active={isCurrentWorkspace}
              onClick={async () => {
                await update({
                  subdomain: item.subdomain,
                });
                setCurrentWorkspace(item);
                push(Routes.workspace.dashboard, item.subdomain);
              }}
            />
          );
        })}
      </div>
      <Divider />
    </>
  );
};
