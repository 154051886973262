import { Button, ButtonKind } from "../../button";
import { SignupMenuButtonProps } from "./signup-menu-button.types";

export const SignupMenuButton = ({ onClick }: SignupMenuButtonProps) => {
  return (
    <Button
      kind={ButtonKind.primary}
      onClick={onClick}
      aria-label="Signup menu button"
    >
      Signup
    </Button>
  );
};
