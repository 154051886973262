import { useState } from "react";
import useBreakpoint from "~/hooks/useBreakpoint";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { Drawer } from "../../drawer";
import { WorkspaceButton } from "./components/workspace-button";
import {
  WorkspacesDropdownSubmenuFooter,
  WorkspacesDropdownSubmenuContent,
  WorkspacesDropdownMenuFooter,
  WorkspacesDropdownMenuContent,
} from "./components";
import { Divider } from "../../divider";
import useClientSubdomain from "~/hooks/use-client-subdomain";
import { EmployeeWorkspaceButton } from "./components/employee-workspace-button";
import { EmployeeWorkspacesDropdownMenuContent } from "./components/employee-content";
import useNavbarType, { NavbarType } from "~/hooks/use-navbar-type";

export const WorkspacesDropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { lg } = useBreakpoint();

  const navbarType = useNavbarType();

  const subdomain = useClientSubdomain();

  const employerContent = (
    <WorkspacesDropdownMenuContent onOpenSubMenu={setIsSubMenuOpen} />
  );
  const employeeContent = <EmployeeWorkspacesDropdownMenuContent />;

  return (
    <div className="flex w-full items-center justify-center">
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger
          aria-label="Workspace Button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={!subdomain ? "w-[28px]" : ""}
        >
          {navbarType === NavbarType.COMPANY_WORKSPACE ? (
            <WorkspaceButton />
          ) : (
            <EmployeeWorkspaceButton />
          )}
        </DropdownMenuTrigger>

        {lg ? (
          <DropdownMenuContent
            className="w-[320px] rounded-xl bg-white p-0"
            align="start"
            collisionPadding={10}
          >
            {navbarType === NavbarType.COMPANY_WORKSPACE
              ? employerContent
              : employeeContent}
            {navbarType === NavbarType.COMPANY_WORKSPACE && (
              <>
                <Divider />
                <WorkspacesDropdownMenuFooter />
              </>
            )}
          </DropdownMenuContent>
        ) : (
          <>
            <Drawer
              isOpen={isOpen}
              onOpenChange={setIsOpen}
              contentClassName="p-0"
              footerContent={<WorkspacesDropdownMenuFooter />}
            >
              {navbarType === NavbarType.COMPANY_WORKSPACE
                ? employerContent
                : employeeContent}
            </Drawer>
            <Drawer
              isOpen={isSubMenuOpen}
              onOpenChange={setIsSubMenuOpen}
              contentClassName="p-0"
              footerContent={<WorkspacesDropdownSubmenuFooter />}
            >
              <WorkspacesDropdownSubmenuContent />
            </Drawer>
          </>
        )}
      </DropdownMenu>
    </div>
  );
};
