import { Button, ButtonKind } from "../../button";
import { LogoutMenuButtonProps } from "./logout-menu-button.types";

export const LogoutMenuButton = ({ onClick }: LogoutMenuButtonProps) => {
  return (
    <Button
      kind={ButtonKind.secondary}
      onClick={onClick}
      aria-label="logout menu button"
    >
      Logout
    </Button>
  );
};
