import React, { PropsWithChildren } from "react";
import {
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { WorkspaceItem } from "../workspace-item";
import { WorkspacesDropdownSubmenuContent } from "./content";

import { useNavbarContext } from "../../../navbar-context/navbar-context";

export const WorkspacesDropdownSubmenu: React.FC<PropsWithChildren> = () => {
  const { workspaces, currentWorkspace } = useNavbarContext();

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger hideTrigger={true} className="p-0 pb-2 pt-1">
        <WorkspaceItem />
      </DropdownMenuSubTrigger>

      {currentWorkspace && !!workspaces?.length && (
        <DropdownMenuSubContent
          sideOffset={14}
          alignOffset={-32}
          className="w-full rounded-xl bg-white p-0"
        >
          <WorkspacesDropdownSubmenuContent />
        </DropdownMenuSubContent>
      )}
    </DropdownMenuSub>
  );
};
