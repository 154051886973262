export const MOCK_COMPANIES_AVATAR = [
  {
    name: "Scalis",
    imageSrc: "/images/scalis-logo.svg",
  },
  {
    name: "Google",
    imageSrc: "/images/google-logo.svg",
  },
  {
    name: "Microsoft",
    imageSrc: "/images/microsoft-logo.svg",
  },
  {
    name: "Skype",
    imageSrc: "/images/skype-logo.svg",
  },
  {
    name: "GitHub",
    imageSrc: "/images/github-logo.svg",
  },
  {
    name: "Blizzard",
    imageSrc: "/images/blizzard-logo.svg",
  },
];
