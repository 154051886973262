import { cn } from "~/utils/cn";
import { LogoutMenuButton } from "../../../logout-menu-button";
import { signOut } from "next-auth/react";
import ScalisLogo from "../../../logo/scalis-logo";

type Props = {
  isLoggedOut: boolean;
  isLoading: boolean;
};

export const NavbarTemplateLogoOnly = ({
  isLoggedOut,
  isLoading,
}: Partial<Props>) => {
  return (
    <div
      className={cn(
        "flex h-[3.313rem] w-full items-center justify-between border-b border-neutral-10 bg-neutral-00 px-4 py-[0.375rem]",
      )}
    >
      <div className="flex w-full items-center gap-6 lg:w-auto">
        <div className="w-24 overflow-hidden">
          <ScalisLogo />
        </div>
      </div>
      <div>
        {!isLoggedOut && !isLoading && <LogoutMenuButton onClick={signOut} />}
      </div>
    </div>
  );
};
