import { cn } from "~/utils/cn";
import { useSignedAWSUrl } from "~/hooks/file-upload/use-signed-aws-url";
import { Workspace } from "../../../navbar-context/navbar-context.types";
import { CompanyAvatar } from "~/scalis-components/core/company-avatar";
import { Body, BodyType } from "~/scalis-components/core/typography";

interface WorkspacesDropdownSubmenuItemProps extends Workspace {
  active: boolean;
  onClick: () => void;
}

export const WorkspacesDropdownSubmenuItem = ({
  active,
  onClick,
  ...workspace
}: WorkspacesDropdownSubmenuItemProps) => {
  const logoUrl = useSignedAWSUrl(workspace.imageSrc);
  const text = workspace.name ?? workspace.subdomain;

  return (
    <div
      className={cn(
        "flex w-full cursor-pointer items-center justify-between gap-2 rounded-xl p-2 hover:cursor-pointer hover:bg-neutral-20",
        active && "bg-neutral-20",
      )}
      aria-label={text}
      onClick={onClick}
    >
      <div className="flex gap-2">
        <CompanyAvatar size="md" imageSrc={logoUrl} name={text} />
        <div className="flex flex-col">
          <Body type={BodyType.emphasys}>{text}</Body>
          <span className="text-sm uppercase leading-4 text-neutral-secondary">
            {workspace.id}
          </span>
        </div>
      </div>
      {active && <i className="fa-solid fa-circle-dot px-4 py-2" />}
    </div>
  );
};
