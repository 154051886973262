import React from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { useNavbarContext } from "../../../navbar-context/navbar-context";
import { useSession } from "next-auth/react";

export const WorkspacesDropdownSubmenuFooter: React.FC = () => {
  const router = useRouter();
  const { setCurrentWorkspace } = useNavbarContext();
  const { update } = useSession();
  return (
    <Link
      href="#"
      onClick={async e => {
        e.preventDefault();

        await update({
          subdomain: null,
        });

        setCurrentWorkspace(null);
        router.push("/jobseeker/dashboard");
      }}
      className="flex w-full items-center justify-between gap-1 px-4 py-2 text-brand-primary-rest  hover:cursor-pointer"
    >
      <div className="flex items-center gap-2 text-sm font-medium">
        Switch to Job Seeker
      </div>

      <i className="fa-regular fa-arrow-right-from-bracket" />
    </Link>
  );
};
