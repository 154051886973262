import Link from "next/link";
import React from "react";
import { Divider } from "~/scalis-components/core/divider";
import { Icon } from "~/scalis-components/core/icon";
import { Body, BodySize, Title, TitleSize, TitleType } from "~/scalis-components/core/typography";
import { Routes } from "~/src/app/_constants/routes";


export const EmployeeWorkspacesDropdownMenuContent = () => {

  return (
    <div className="flex w-full flex-col">
      <div className="p-4">
        <Title className="p-0" size={TitleSize.extraSmall}>
          SCALIS For Business
        </Title>
      </div>
      <Divider />
      <div className="p-2">
        <Link href={Routes.landing.forEmployers} className="flex w-full items-start gap-2 hover:bg-neutral-10 rounded-lg p-2">
            <div className="rounded-full bg-brand-primary-dark-00 w-[48px] h-[48px] flex items-center justify-center text-white">
                <Icon icon="fa-solid fa-poll-people" size={16} />
            </div>
            <div className="flex-1">
                <Title type={TitleType.emphasys}>SCALIS atsOS</Title>
                <Body size={BodySize.small}>Find, manage applications, and hire on SCALIS with our best-in-class ATS!</Body>
            </div>
        </Link>
      </div>
    </div>
  );
};
