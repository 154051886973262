import React, { PropsWithChildren } from "react";
import { CompanyAvatar } from "~/scalis-components/core/company-avatar";
import { Icon } from "~/scalis-components/core/icon";
import { Body, BodyType } from "~/scalis-components/core/typography";
import { useNavbarContext } from "../../../navbar-context/navbar-context";
import { getWorkspace } from "../workspace-button";
import { Workspace } from "../../../navbar-context/navbar-context.types";
import { useSignedAWSUrl } from "~/hooks/file-upload/use-signed-aws-url";

interface WorkspaceItemProps {
  onClick?: (isOpen: boolean) => void;
}

export const WorkspaceItem: React.FC<PropsWithChildren<WorkspaceItemProps>> = ({
  onClick,
}) => {
  const { workspaces, currentWorkspace } = useNavbarContext();
  const { imageSrc, subdomain, id, name } = getWorkspace(
    currentWorkspace?.id!,
    workspaces ?? [],
  ) as Workspace;
  const logoUrl = useSignedAWSUrl(imageSrc)
  return (
    <button
      type="button"
      aria-label="workspaces submenu trigger"
      role="button"
      onClick={() => onClick?.(true)}
      className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-xl p-2 hover:bg-neutral-10"
    >
      <div className="flex gap-2">
        <CompanyAvatar size="md" imageSrc={logoUrl} name={name ?? subdomain} />
        <div className="flex flex-col items-start">
          <Body type={BodyType.emphasys}>{name ?? subdomain}</Body>
          <span className="text-xs font-normal leading-4 text-neutral-secondary">
            {id}
          </span>
        </div>
      </div>

      <Icon
        icon="fa-solid fa-chevron-right"
        className="items-baseline text-xs"
      />
    </button>
  );
};
